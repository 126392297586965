import "../styles/doublecontentbanner.scss";
import * as CubeTrackingAK from "./tracking/cube/AktionskommunikationsTracking";
import { doublecontentbanner, middle } from "./ENTDWindow";
import * as CubeTrackingAB from "./tracking/cube/AbTestTracking";
import * as GtmTrackingAB from "./tracking/gtm/AbTestTracking";

class DoubleContentbanner extends HTMLElement {
    private kampagneName: string | undefined | null;
    private observer: IntersectionObserver;
    private alreadyTracked: boolean;
    private internalEventsAbortController: AbortController | undefined;

    constructor() {
        super();
        this.observer = new IntersectionObserver(this.handleObservationEvent.bind(this));
        this.alreadyTracked = false;
    }

    connectedCallback(): void {
        this.internalEventsAbortController = new AbortController();
        this.kampagneName = this.querySelector<HTMLDivElement>('[data-select="doublecontentbanner"]')?.getAttribute("data-kampagne-name");
        const signal = this.internalEventsAbortController.signal;
        this.setupLinkClickDetail(signal);
        this.observer.observe(this);
    }

    private handleObservationEvent(entries: IntersectionObserverEntry[]): void {
        entries.forEach((entry) => {
            if (entry.isIntersecting && !this.alreadyTracked && this.kampagneName) {
                CubeTrackingAK.trackView(this.kampagneName, middle, doublecontentbanner);
                const el = this.querySelector<HTMLDivElement>('[data-select="doublecontentbanner"]');
                const abTestHeaderName = el?.getAttribute("data-abtest-name");
                const abTestHeaderValue = el?.getAttribute("data-abtest-value");
                CubeTrackingAB.trackView(abTestHeaderValue, abTestHeaderName);
                GtmTrackingAB.trackView(abTestHeaderValue, abTestHeaderName);
                this.alreadyTracked = true;
            }
        });
    }

    disconnectedCallback(): void {
        this.observer.disconnect();
        this.internalEventsAbortController?.abort();
    }

    private setupLinkClickDetail(signal: AbortSignal): void {
        const linkLeft = this.querySelector<HTMLAnchorElement>('[data-select="link-left"]');
        const linkRight = this.querySelector<HTMLAnchorElement>('[data-select="link-right"]');

        linkLeft?.addEventListener(
            "click",
            (event) => {
                const target = event.target as HTMLAnchorElement;
                if (target && this.kampagneName) {
                    CubeTrackingAK.trackLinkClick(this.kampagneName, target.href, middle, doublecontentbanner, 1);
                }
            },
            { signal },
        );

        linkRight?.addEventListener(
            "click",
            (event) => {
                const target = event.target as HTMLAnchorElement;
                if (target && this.kampagneName) {
                    CubeTrackingAK.trackLinkClick(this.kampagneName, target.href, middle, doublecontentbanner, 2);
                }
            },
            { signal },
        );
    }
}

customElements.define("camp-doublecontentbanner", DoubleContentbanner);
